import React from "react"
import PropTypes from "prop-types"
const SectionTitle = ({
    heading,
    description,
    align,
    color,
    showDescription
}) => {
    const title = {
        showDescription: showDescription || false,
        align: align || "center",
        color: color || "primary"
    }
    return (
        <div className="section-title">
            <div className="container">
                <div align={title.align} className="mx-auto">
                    <h2 className="font-bold">{heading}</h2>
                    {title.showDescription && (
                        <div className="max-w-xl mt-2 leading-7 text-18base">
                            {description}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
SectionTitle.propTypes = {
    heading: PropTypes.object,
    description: PropTypes.object,
    align: PropTypes.oneOf(["left", "right", "center"]),
    color: PropTypes.oneOf(["white", "primary"]),
    showDescription: PropTypes.bool
}
export default SectionTitle
